function initTabs() {
  const pageWrapper = document.querySelector('.contacts-page-wrapper');
  const tabButton = document.querySelectorAll('.tab-btn');
  const contents = document.querySelectorAll('.content');

  tabButton.forEach((b) => {
    b.onclick = (e) => {
      const id = e.target.dataset.id;
      if (id) {
        tabButton.forEach((btn) => {
          btn.classList.remove('active');
        });
        e.target.classList.add('active');

        contents.forEach((content) => {
          content.classList.remove('active');
        });
        const element = document.getElementById(id);
        element.classList.add('active');
        pageWrapper.dataset.activeTab = id;
      }
    };
  });
}
export { initTabs };
