function initNotifier() {
  const NOTIFIER = {
    KEY: 'notifier',
    notifier: {show: true},

    init: function () {
      NOTIFIER.config = {
        $agreeBtn: $('.js-notifier-btn'),
        $notifierCont: $('.notifier'),
      };

      const notifier = localStorage.getItem(NOTIFIER.KEY);

      if (notifier) this.notifier = JSON.parse(notifier);
      if (this.notifier.show) this.config.$notifierCont.removeClass('hidden');
      this.bindUIActions();
    },

    bindUIActions: function () {
      this.config.$agreeBtn.on('click', (e) => {
        e.preventDefault();

      this.notifier.show = false;
      this.config.$notifierCont.addClass('hidden');
      this.sync();
    })
    },

    async sync()
  {
    localStorage.setItem(
      NOTIFIER.KEY,
      JSON.stringify(NOTIFIER.notifier)
    );
  }
,
}
  ;

  NOTIFIER.init();

  let collapsibleHeaders = document.getElementsByClassName('collapsible__header');

  Array.from(collapsibleHeaders).forEach(header => {
    header.addEventListener('click', () => {
    header.parentElement.classList.toggle('collapsible--open');
})
  ;
})
  ;
  $('.setting-cookis').click(function () {
    $('.js-cookies').hide()
    $('.js-cookies-setting').show()
  })
  $('.js-save-choice').click(function () {
    $('.js-cookies').show()
    $('.js-cookies-setting').hide()
  })
  $('.js-cancel-choice').click(function () {
    $('.notifier').hide()
  })

}

export {initNotifier};
