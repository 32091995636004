import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

function initSliders() {
  const packSwiper = new Swiper('.pack-slider', {
    slidesPerView: 1.2,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1921: {
        slidesPerView: 2.1,
      },
      681: {
        slidesPerView: 1.5,
        spaceBetween: 60,
      },
    },
  });

  const advertisingSwiper = new Swiper('.advertising-slider', {
    slidesPerView: 1.2,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1921: {
        slidesPerView: 2.1,
      },
      681: {
        slidesPerView: 1.5,
        spaceBetween: 60,
      },
    },
  });

  const paginateLabels = [
    '1997',
    '2000',
    '2004',
    '2005',
    '2010',
    '2015',
    '2019',
    '2021',
  ];
  const aboutUsSwiper = new Swiper('.about-us-slider', {
    slidesPerView: 1,
    spaceBetween: 20,
    init: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return (
          '<span class="' + className + '">' + paginateLabels[index] + '</span>'
        );
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1921: {
        slidesPerView: 2.4,
      },
      768: {
        slidesPerView: 1.5,
        spaceBetween: 60,
      },
    },
  });

  aboutUsSwiper.on('init', ({ activeIndex }) => {
    $(`.text-slide-${activeIndex}`).addClass('active');
  });
  aboutUsSwiper.on('slideChange', ({ activeIndex }) => {
    $('.text-slide').removeClass('active');
    $(`.text-slide.text-slide-${activeIndex}`).addClass('active');
  });
  aboutUsSwiper.init();
}

export { initSliders };
