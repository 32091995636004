export function initAttach() {
  const inputs = document.querySelectorAll('.attach-field');

  for (let i = 0, len = inputs.length; i < len; i++) {
    customInput(inputs[i]);
  }

  function customInput(el) {
    const fileInput = el.querySelector('[type="file"]');
    const label = el.querySelector('[data-js-label]');

    fileInput.onchange = function () {
      if (!fileInput.value) return;

      label.innerText = fileInput.value.replace(/^.*[\\\/]/, '');
    };
  }
}
