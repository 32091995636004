import Swal from 'sweetalert2';

function initModal(title, text) {
    Swal.fire({
      position: 'center',
      title: title,
      text: text,
      padding: '40px 70px 50px',
      background: '#253746',
      showConfirmButton: false,
    });
}
window.openModal = initModal;

export { initModal };
