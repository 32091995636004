function initMobileMenu() {
  const burgerMenu = document.getElementById('burger-menu');
  const overlay = document.getElementById('menu');
  const body = document.querySelector('body');

  burgerMenu.addEventListener('click', function () {
    this.classList.toggle('close');
    overlay.classList.toggle('overlay');
    body.classList.toggle('noscroll');
  });
}

export { initMobileMenu };
