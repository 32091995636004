
const animateCount = ($el, maxCount, stepMultiplier, speed) => {
  let currentCount = 0;
  const interval = setInterval(() => {
    currentCount = Math.min(
      currentCount + Math.ceil(Math.random() * stepMultiplier),
      maxCount
    );
    $el.html(currentCount);
    if (currentCount === maxCount) clearInterval(interval);
  }, speed);
};
const animateCountYear = ($el, maxCount, stepMultiplier, speed) => {
  let currentCount = 0;
  const interval = setInterval(() => {
    currentCount = Math.min(
      currentCount + Math.ceil(Math.random() * stepMultiplier),
      maxCount
    );
    $el.html(currentCount);
    if (currentCount === maxCount) {
      clearInterval(interval)
      $el.html('20+');
    }
  }, speed);
};

function generateTextAnimScenes(controller, ...triggerElements) {
  triggerElements.forEach((triggerElement) => {
    new ScrollMagic.Scene({
      triggerElement,
      triggerHook: 0.9,
      offset: 50,
      reverse: true,
    })
      .setClassToggle(triggerElement, 'visible')
      .addTo(controller);
  });
}

export const initParallax = () => {
  const controller = new ScrollMagic.Controller();

  new ScrollMagic.Scene({
    triggerElement: '.parallax',
    triggerHook: 1,
    duration: '100%',
  })
    .setTween(
      TweenMax.from('.parallax-img', 1, { y: '-50%', ease: Power0.easeNone })
    )
    .addTo(controller);
};

export function initHeaderAnimations() {
  const controller = new ScrollMagic.Controller();

  new ScrollMagic.Scene({
    triggerElement: 'header',
    triggerHook: 0,
    offset: 0,
    reverse: true,
  })
    .setClassToggle('header', 'visible')
    .addTo(controller);
}

export const initHeroTextAnimations = () => {
  const controller = new ScrollMagic.Controller();

  new ScrollMagic.Scene({
    triggerElement: 'header',
    triggerHook: 0,
    offset: 0,
    reverse: true,
  })
    .setClassToggle('#hero-text-anim', 'visible')
    .addTo(controller);
};

export function initHomeAnimations() {
  const controller = new ScrollMagic.Controller();

  // generateNumbersAnimScenes (
  //   controller,
  //   '#spaces-count-1200'
  // );

  generateTextAnimScenes(
    controller,
    '#packs-anim',
    '#packParfumsImg',
    '#packParfums',
    '#paskSweets',
    '#packGoods',
    '#printing'
  );

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-1200',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCount($('#spaces-count-1200'), 1200, 100, 10))
    .setClassToggle('#spaces-count-1200', 'visible')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-4000',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCount($('#spaces-count-4000'), 4000, 100, 20))
    .setClassToggle('#spaces-count-4000', 'visible')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-15000',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCount($('#spaces-count-15000'), 23000, 500, 60))
    .setClassToggle('#spaces-count-15000', 'visible')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-53',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCount($('#spaces-count-53'), 100, 1, 20))
    .setClassToggle('#spaces-count-53', 'visible')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-20',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCountYear($('#spaces-count-20'), 20, 1, 10))
    .setClassToggle('#spaces-count-20', 'visible')
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: '#spaces-count-600',
    triggerHook: 0.9,
    offset: 50,
    reverse: false,
  })
    .on('start', () => animateCount($('#spaces-count-600'), 600, 20, 5))
    .setClassToggle('#spaces-count-600', 'visible')
    .addTo(controller);

}
