// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//require social-share-button

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

import '../scss/application';
require.context('../images', true);

import { initSliders } from './sliders';
import { initAccordion } from './accordion';
import { initTabs } from './tabs';
import { initMobileMenu } from './mobile-menu';
import {
  initHomeAnimations,
  initHeaderAnimations,
  initHeroTextAnimations,
  initParallax,
} from './animations';
import { initNotifier } from './notifier';
import { initModal } from './modal';
import { initAttach } from './attach';

document.addEventListener('turbolinks:load', () => {
  const hasClassName = (cn) =>
    document.querySelector('body').classList.contains(cn);

  initMobileMenu();
  initNotifier();
  initHeaderAnimations();
  initAccordion();
  initParallax();
  const heroTextPages = [
    'home-page',
    'products-page',
    'about-us-page',
    'equipments-page',
    'cardboard-page',
    'product-page',
  ];

  if (heroTextPages.some(hasClassName)) initHeroTextAnimations();

  const accordion = ['suppliers-page', 'company-policies-page'];

  if (accordion.some(hasClassName)) initAccordion();

  if (hasClassName('home-page')) {
    initHomeAnimations();
    initSliders();
    initAttach();
    const video = document.querySelector('#video_play');
    const first_animation = document.getElementById('hero-text-anim')
      function isMobile() {
          return window.innerWidth <= 768;
      }
      if (!isMobile()) {
          video.setAttribute('autoplay', true);
      }  
      if (isMobile()) {
        first_animation.classList.remove('show-text');
      }
  }

  if (hasClassName('about-us-page')) {
    initSliders();
  }

  if (hasClassName('contacts-page')) {
    initTabs();
  }

  if (hasClassName('suppliers-page')) {
    initAccordion();
  }

  if (hasClassName('company-policies-page')) {
    initAccordion();
  }
$($('#resume_check')).click(function() {
    if (!$(this).is(':checked')) {
        $("#submit-resumes-btn").attr('disabled', 'disabled');
    } else {
        $("#submit-resumes-btn").removeAttr('disabled');
    }
});

$($('#order_check')).click(function() {
    if (!$(this).is(':checked')) {
        $("#submit-feedback-btn").attr('disabled', 'disabled');
    } else {
        $("#submit-feedback-btn").removeAttr('disabled');
    }
})
});

$(window).ready(function(){
  $(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
      $('#btnTop').fadeIn();
    } else {
      $('#btnTop').fadeOut();
    }
  });
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".description"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }

});
